<main class="scientific">
    <div class="main">
        <section class="section titleSection">
            <div class="content content_medium">
                <h1 class="section-title" translate>PhysiciansPage.IntroSection.Title</h1>
            </div>
        </section>
    </div>
    <section class="section">
        <div class="content content_tight intro-faq-block">
            <div class="intro-faq-block__content">
                <p
                    [innerHTML]="'PhysiciansPage.IntroSection.Text' | translate"
                ></p>
                <section class="step">
                    <div class="step__data">
                        <a
                            target="_blank"
                            [href]="'PhysiciansPage.step.href' | translate"
                        >
                            <button translate>
                                PhysiciansPage.step.Button
                            </button>
                        </a>
                    </div>
                </section>

                <p
                    [innerHTML]="'PhysiciansPage.introduction.Text' | translate"
                ></p>
                <div
                    *ngFor="
                        let PTinfo of 'PhysiciansPage.WISection' | translate
                    "
                >
                    <p [innerHTML]="PTinfo.Title"></p>
                    <p [innerHTML]="PTinfo.Text"></p>
                </div>
            </div>
        </div>
    </section>
</main>
