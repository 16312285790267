<main routeTransformer>
    <section class="section faq-section">
        <div class="content content_medium">
            <h1 class="section-title" translate>FaqPage.Title</h1>
        </div>
    </section>
    <section class="section">
        <div class="content content_tight intro-faq-block">
            <div class="intro-faq-block__content">
                <p [innerHTML]="'FaqPage.IntroText.Paragraph1' | translate"></p>
                <p>
                    <span translate>FaqPage.IntroText.Paragraph2.Part1</span>
                    <a class="Link" [href]="infoEmailHref">{{infoEmail}}</a>
                    <span translate>FaqPage.IntroText.Paragraph2.Part2</span>
                    <a class="Link" [href]="phoneHref">{{phone}}</a>.
                </p>
            </div>
        </div>
    </section>
    <div class="faqs">
        <ng-container *ngFor="let faqSection of 'FaqPage.FaqSections' | translate">
            <app-faq-section [faqSection]="faqSection"></app-faq-section>
        </ng-container>
    </div>
    <section class="section">
        <div class="content content_tight">
            <p [innerHTML]="'FaqPage.NeedInfo.Paragraph' | translate"></p>
        </div>
    </section>
</main>
