import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'lib-thank-you-family-history',
    templateUrl: './thank-you-family-history.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class ThankYouFamilyHistoryComponent {
}
