<main class="family-history-thank-you-container">
    <section class="content">
        <img src="assets/images/FHS-Tree1.png" class="image-first" [alt]="'ThankYouFamilyHistory.FirstImage' | translate">
        <img src="assets/images/FHS-Tree2.png" class="image-second" [alt]="'ThankYouFamilyHistory.SecondImage' | translate"/>

        <div class="title" translate>ThankYouFamilyHistory.Title</div>
        <div class="subtitle" translate>ThankYouFamilyHistory.Subtitle</div>

        <a [routerLink]="['/dashboard']" class="button button_small button_secondary" translate>
            ThankYouFamilyHistory.ReturnToDashboard
        </a>
    </section>
</main>
