<main class="main">
    <section class="section">
        <div class="content content_wide intro-media-block">
            <div class="intro-media-block__text">
                <h1 class="no-margin" translate>
                    HomePage.IntroductionSection.Title
                </h1>
                <p translate>
                    HomePage.IntroductionSection.Text
                </p>
                <div class="intro-media-block__buttons">
                    <a *ngIf="!isAuthenticated" routerLink="count-me-in" (click)="sendCountMeInAnalytics()"
                        class="button button_medium button_primary" translate>
                        Common.Buttons.CountMeIn
                    </a>
                    <a routerLink="/more-details" class="button button_medium button_secondary" translate>
                        Common.Buttons.LearnMore
                    </a>
                </div>
            </div>
            <div class="youtube-video">
                <iframe [title]="'HomePage.IntroductionSection.IframeTitle' | translate" class="youtube-video__iframe"
                    src="https://www.youtube.com/embed/d6UcF6ngNKg" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </section>
    <section class="navigation-section">
        <input (click)="scrollToStepsSection()" type="image" src="assets/images/arrow.svg"
            [alt]="'HomePage.NavigationSection.Button.Alt' | translate">
    </section>
    <section class="organizations-section">
        <img lazy-resource src="assets/images/logo-broad-institute.svg" class="organizations-section__img"
            [alt]="'HomePage.OrganizationsSection.BroadLogo.Alt' | translate">
        <img lazy-resource src="assets/images/logo-new-DFCI.jpg" class="organizations-section__img"
            [alt]="'HomePage.OrganizationsSection.DanaFarberLogo.Alt' | translate">
        <img lazy-resource src="assets/images/PE-CGS-logo.png" class="organizations-section__img"
          [alt]="'HomePage.OrganizationsSection.PECGSLogo.Alt' | translate">
    </section>

    <section class="section funding-section">
        <div class="content content_medium funding-block">
            <div class="funding-block__text">
                <div>
                  <p [innerHTML]="'HomePage.fundingText' | translate"></p>
                </div>
            </div>
        </div>
    </section>

    <section #scrollAnchor class="background-decorator"></section>
    <section class="section steps-section">
        <div class="content content_medium steps-block">
            <h2 class="section-title" translate>HomePage.StepsSection.Title</h2>
            <div class="steps">
                <div class="step">
                    <div class="step__img">
                        <img lazy-resource src="assets/images/clipboard.svg"
                            [alt]="'HomePage.StepsSection.Step1.ImageAlt' | translate">
                    </div>
                    <p translate>HomePage.StepsSection.Step1.Text</p>
                </div>
                <div class="arrow">
                    <img lazy-resource src="assets/images/step-arrow.svg"
                        [alt]="'HomePage.StepsSection.NextStepArrowAlt' | translate">
                </div>
                <div class="step">
                    <div class="step__img">
                        <img lazy-resource src="assets/images/microscope.svg"
                            [alt]="'HomePage.StepsSection.Step2.ImageAlt' | translate">
                    </div>
                    <p translate>HomePage.StepsSection.Step2.Text</p>
                </div>
                <div class="arrow">
                    <img lazy-resource src="assets/images/step-arrow.svg"
                        [alt]="'HomePage.StepsSection.NextStepArrowAlt' | translate">
                </div>
                <div class="step">
                    <div class="step__img">
                        <img lazy-resource src="assets/images/heart.svg"
                            [alt]="'HomePage.StepsSection.Step3.ImageAlt' | translate">
                    </div>
                    <p translate>HomePage.StepsSection.Step3.Text</p>
                </div>
                <div class="arrow">
                    <img lazy-resource src="assets/images/step-arrow.svg"
                        [alt]="'HomePage.StepsSection.NextStepArrowAlt' | translate">
                </div>
                <div class="step">
                    <div class="step__img">
                        <img lazy-resource src="assets/images/woman-scientist.png"
                            [alt]="'HomePage.StepsSection.Step4.ImageAlt' | translate">
                    </div>
                    <div>
                        <p translate>HomePage.StepsSection.Step4.Text</p>
                        <a routerLink="/participation" class="button button_medium button_secondary step-button" translate>
                            Common.Buttons.LearnMore
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section help-section">
        <div class="content content_medium help-block">
            <div class="help-block__text">
                <div>
                    <p class="big bold" translate>HomePage.HelpSection.Diagnosed.Title</p>
                    <p translate>HomePage.HelpSection.Diagnosed.Text</p>
                </div>
                <div>
                    <p class="big bold" translate>HomePage.HelpSection.PassedAway.Title</p>
                    <p translate>HomePage.HelpSection.PassedAway.Text</p>
                    <div class="more-details-container">
                        <a routerLink="more-details" class="button button_medium button_secondary" translate>
                            Common.Buttons.FAQ
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section">
        <div class="content content_wide media-block">
            <div class="media-block__text">
                <h2 translate>
                    HomePage.ParticipatingSection.Title
                </h2>
                <p class="no-margin">
                    <span class="bold" translate>HomePage.ParticipatingSection.AllowList.Title</span>
                </p>
                <ul>
                    <li translate>HomePage.ParticipatingSection.AllowList.Option1</li>
                    <li translate>HomePage.ParticipatingSection.AllowList.Option2</li>
                </ul>
                <p class="no-margin">
                    <span class="bold" translate>HomePage.ParticipatingSection.Opt-inToList.Title</span>
                </p>
                <ul>
                    <li translate>HomePage.ParticipatingSection.Opt-inToList.Option1</li>
                    <li translate>HomePage.ParticipatingSection.Opt-inToList.Option2</li>
                    <li translate>HomePage.ParticipatingSection.Opt-inToList.Option3</li>
                    <li translate>HomePage.ParticipatingSection.Opt-inToList.Option4</li>
                </ul>
                <p>
                    <span class="bold" translate>HomePage.ParticipatingSection.ClosingText.Part1</span>
                    <span translate>HomePage.ParticipatingSection.ClosingText.Part2</span>
                </p>
                <div>
                    <a routerLink="/participation" (click)="sendCountMeInAnalytics()"
                        class="button button_medium button_primary" translate>
                        Common.Buttons.LearnMoreAboutParticipating
                    </a>
                </div>
            </div>
            <div class="youtube-video">
                <iframe lazy-resource [title]="'HomePage.ParticipatingSection.IframeTitle' | translate"
                    class="youtube-video__iframe" src="https://www.youtube.com/embed/n0tHdBHsTcw" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </section>
    <section class="section gallery-section">
        <div class="content content_medium gallery-block">
            <div class="gallery-header">
                <h2 class="gallery-header__title" [innerHTML]="'HomePage.GallerySection.Title' | translate" >HomePage.GallerySection.Title</h2>
                <img lazy-resource class="gallery-header__image" src="assets/images/molecule.svg"
                    [alt]="'HomePage.GallerySection.TitleImageAlt' | translate">
            </div>
            <app-gallery></app-gallery>
        </div>
    </section>
    <section class="section">
        <div class="content content_wide partners-block">
            <h2 translate>
                HomePage.PartnersSection.Title
            </h2>
            <div class="partners">
                <div class="partners__group">
                    <a href="https://www.mibagents.org/" target="_blank">
                        <img lazy-resource class="image" src="assets/images/partner-mib.jpg"
                            [alt]="'HomePage.PartnersSection.ImagesAlts.MBI' | translate">
                    </a>
                    <a href="https://www.quadw.org/" target="_blank">
                        <img lazy-resource class="image" src="assets/images/partner-quadw.jpg"
                            [alt]="'HomePage.PartnersSection.ImagesAlts.QUADW' | translate">
                    </a>
                    <a href="https://childrenscancer.org/zach/" target="_blank">
                        <img lazy-resource class="image" src="assets/images/partner-zsof.png"
                            [alt]="'HomePage.PartnersSection.ImagesAlts.ZSOF' | translate">
                    </a>
                </div>
                <div class="partners__group">
                    <a href="https://becauseofdaniel.org/" target="_blank">
                        <img lazy-resource class="image" src="assets/images/partner-bod.png"
                            [alt]="'HomePage.PartnersSection.ImagesAlts.BoD' | translate">
                    </a>
                    <a href="https://www.oscollaborative.org/" target="_blank">
                        <img lazy-resource class="image" src="assets/images/partner-os-collaborative.svg"
                            [alt]="'HomePage.PartnersSection.ImagesAlts.OsCollaborative' | translate">
                    </a>
                    <a href="https://mcamf.org/" target="_blank">
                        <img lazy-resource class="image" src="assets/images/partner-mcamf.png"
                             [alt]="'HomePage.PartnersSection.ImagesAlts.MCAMF' | translate">
                    </a>
                </div>
                <div class="partners__group">
                  <a href="https://osinst.org/" class="alignCenter" target="_blank">
                    <img lazy-resource class="image" src="assets/images/OSINST-logo.png"
                         [alt]="'HomePage.PartnersSection.ImagesAlts.OSINST' | translate">
                  </a>
                </div>
            </div>
        </div>
    </section>
    <section class="section">
        <div class="content content_wide social-media-block">
            <div class="social-media-header">
                <h2 class="social-media-header__text" translate>
                    HomePage.SocialMediaSection.Title
                </h2>
                <div class="social-media-logos">
                    <a (click)="sendSocialMediaAnalytics('twitter')" class="social-media-logo" [href]="twitterUrl"
                        target="_blank">
                        <img lazy-resource class="social-media-logos__img" src="assets/images/twitter.png"
                            [alt]="'Common.Alts.Twitter' | translate">
                    </a>
                    <a (click)="sendSocialMediaAnalytics('facebook')" class="social-media-logo" [href]="facebookUrl"
                        target="_blank">
                        <img lazy-resource class="social-media-logos__img" src="assets/images/facebook.png"
                            [alt]="'Common.Alts.Facebook' | translate">
                    </a>
                    <a (click)="sendSocialMediaAnalytics('instagram')" class="social-media-logo" [href]="instagramUrl"
                        target="_blank">
                        <img lazy-resource class="social-media-logos__img" src="assets/images/instagram.png"
                            [alt]="'Common.Alts.Instagram' | translate">
                    </a>
                </div>
            </div>
            <div class="feeds">
                <div class="twitter-feed">
                    <p class="big bold" translate>HomePage.SocialMediaSection.TwitterFeedTitle</p>
                    <toolkit-twitter-timeline-widget></toolkit-twitter-timeline-widget>
                </div>
                <div>
                    <p class="big bold" translate>HomePage.SocialMediaSection.InstagramFeedTitle</p>
                    <toolkit-instagram-feed-lightswitch-plugin [widgetId]="lightswitchWidgetId">
                    </toolkit-instagram-feed-lightswitch-plugin>
                </div>
            </div>
        </div>
    </section>
    <section class="section">
        <div class="content content_wide stay-informed-block">
            <div>
                <h3 class="no-margin" translate>
                    HomePage.MailingListSection.Title
                </h3>
                <p class="no-margin" translate>
                    HomePage.MailingListSection.Text
                </p>
            </div>
            <div>
                <button (click)="joinMailingList()"
                    [attr.aria-label]="'Common.Buttons.MailingList.AriaLabel' | translate"
                    class="button button_medium button_secondary" translate>
                    Common.Buttons.MailingList.Title
                </button>
            </div>
        </div>
    </section>
    <section class="background-decorator"></section>
    <section class="section join-us-section">
        <div class="content content_wide join-us-block">
            <div class="join-us-content">
                <h2 class="big no-margin" translate>HomePage.ClosingSection.Title</h2>
                <p class="extra-margin" translate>
                    HomePage.ClosingSection.Text
                </p>
                <a routerLink="count-me-in" (click)="sendCountMeInAnalytics()" class="button button_big button_primary"
                    translate>
                    Common.Buttons.CountMeIn
                </a>
            </div>
        </div>
    </section>
</main>
