<header class="header" [ngClass]="{'panel-opened': isPanelOpened, 'header_shadow': isPageScrolled}">
    <div class="header-content" [ngClass]="{'header-content_sticky': headerConfig.stickySubtitle, 'wider-header': headerConfig.showBreadcrumbs}">
        <a class="project-logo" routerLink="/">
            <img class="project-logo__img" src="assets/images/logo-osteo.svg"
                [alt]="'Header.Logo.ImageAlt' | translate">
            <p class="project-logo__text" [innerHTML]="'Header.Logo.Title' | translate"></p>
        </a>
        <div *ngIf="headerConfig.stickySubtitle || headerConfig.showBreadcrumbs" class="activity-heading">
            <div *ngIf="headerConfig.showBreadcrumbs" class="breadcrumbs">
                <app-workflow-progress [currentActivityCode]="headerConfig.currentActivityCode"
                    [workflowStartSectionsVisibility]="headerConfig.workflowStartSectionsVisibility">
                </app-workflow-progress>
            </div>
            <div *ngIf="headerConfig.stickySubtitle" class="sticky" [innerHTML]="headerConfig.stickySubtitle"></div>
        </div>
        <ng-container *ngTemplateOutlet="controls"></ng-container>
        <button class="hamburger hamburger--slider" [ngClass]="{'is-active': isPanelOpened}"
            [attr.aria-label]="'Common.Buttons.HeaderMenu.AriaLabel' | translate" (click)="openCloseMenu()">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    </div>
    <div class="panel-controls">
        <ng-container *ngTemplateOutlet="controls"></ng-container>
    </div>
</header>
<div class="placeholder" [ngClass]="{'placeholder_opened': isPanelOpened}"></div>

<ng-template #controls>
    <nav *ngIf="headerConfig.showMainButtons" class="nav">
        <ul class="navigation">
            <li *ngIf="isAuthenticated" class="navigation__item">
                <ng-container *ngTemplateOutlet="dashboardButton"></ng-container>
            </li>
            <li class="navigation__item">
                <a class="header-link" routerLink="about-us" routerLinkActive="header-link_active" translate>
                    Common.Navigation.AboutUs
                </a>
            </li>
            <li class="navigation__item">
                <a class="header-link" routerLink="more-details" routerLinkActive="header-link_active" translate>
                    Common.Navigation.FAQ
                </a>
            </li>
            <li class="navigation__item">
                <a class="header-link" routerLink="participation" routerLinkActive="header-link_active" translate>
                    Common.Navigation.Participation
                </a>
            </li>
            <li class="navigation__item">
                <a class="header-link" routerLink="scientific-impact" routerLinkActive="header-link_active" translate>
                    Common.Navigation.ScientificImpact
                </a>
            </li>
            <li class="navigation__item">
                <button class="simple-button" [attr.aria-label]="'Common.Buttons.MailingList.AriaLabel' | translate"
                    (click)="openJoinMailingList()" translate>
                    Common.Buttons.MailingList.Title
                </button>
            </li>
            <li class="navigation__item">
                <a class="header-link" routerLink="physicians" routerLinkActive="header-link_active" translate>
                    Common.Navigation.Physicians
                </a>
            </li>
        </ul>
    </nav>
    <div class="languageSelection">
        <ddp-language-selector></ddp-language-selector>
    </div>
    <div class="auth">
        <div *ngIf="isAuthenticated && headerConfig.showDashboardButton" class="auth__dashboard">
            <ng-container *ngTemplateOutlet="dashboardButton"></ng-container>
        </div>
        <div *ngIf="headerConfig.showLoginButton" class="auth__login header-link"
            [ngClass]="{'header-button': !headerConfig.showMainButtons}">
            <ddp-sign-in-out></ddp-sign-in-out>
        </div>
        <a *ngIf="!isAuthenticated && headerConfig.showCmiButton" routerLink="count-me-in" (click)="sendAnalytics()"
            class="button button_small button_primary header-button" [ngClass]="{'button_medium': isPanelOpened}">
            <span translate>Common.Buttons.CountMeIn</span>
        </a>
    </div>
</ng-template>

<ng-template #dashboardButton>
    <a class="header-link" routerLink="dashboard" routerLinkActive="header-link_active">
        <div class="header-link__dashboard">
            <svg class="dashboard-icon" width="22px" height="22px" viewBox="0 0 22 22" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g class="dashboard-icon_color" transform="translate(-385.000000, -41.000000)" stroke="#000000"
                        stroke-width="1.5">
                        <g transform="translate(386.000000, 40.000000)">
                            <g transform="translate(0.000000, 2.000000)">
                                <circle cx="10" cy="8.125" r="4.375"></circle>
                                <path
                                    d="M15.7608333,17.3966667 C14.2362899,15.8625492 12.1628105,14.9998815 10,14.9998815 C7.83718949,14.9998815 5.76371015,15.8625492 4.23916667,17.3966667">
                                </path>
                                <circle cx="10" cy="10" r="9.375"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <span translate>Common.Navigation.Dashboard</span>
    </a>
</ng-template>
